import Dashboard from "../pages/Dashboard";
import ListFormDyn from "../containers/SearchFormContainer";
import UserList from "../partials/datatable";

const routes = [
  {
    component: Dashboard,
    componentName: "Dashboard",
    name: "Dashboard",
    path: "/",
  },
  {
    component: ListFormDyn,
    componentName: "ListFormDyn",
    name: "Add User Form",
    path: "/add-use",
  },
  {
    component: UserList,
    componentName: "UserList",
    name: "User List",
    path: "/user-list",
  },
];

export default routes;
