import React, { useState, useEffect } from "react";
import DossierForm from "./DossierForm";
import { Link } from "react-router-dom";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import useTable from "../../components/useTable";
import * as dossierService from "../../helpers/helper";
import Controls from "../../components/controls/Controls";
import { Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import Popup from "../../components/Popup";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { DeleteOutlined } from "@material-ui/icons";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";

import { headCells } from "./helper";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  tableRow: {
    height: 10,
  },
  tableCell: {
    padding: "0px 16px",
  },
  listTitle: {
    padding: "0px 0px 30px 0px",
  },
}));

export default function Dossiers() {
  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState([]);
  const [popupTitle, setPopupTitle] = useState("Créer Dossier");
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    DossiersGet();
  }, []);

  const DossiersGet = () => {
    dossierService.getAllDossiers().then((result) => {
      setRecords(result);
    });
  };

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        else
          return items.filter((item) =>
            Object.keys(item).some((key) =>
              typeof item[key] === "string" || item[key] instanceof String
                ? item[key]
                    .toLowerCase()
                    .indexOf(target.value.toLowerCase()) !== -1
                : false
            )
          );
      },
    });
  };

  const UpdateDossier = (id) => {
    window.location = "#/menudossier/update/" + id;
  };

  const addOrEdit = (dossier, resetForm) => {
    if (dossier.id == 0) dossierService.insertDossier(dossier);
    else dossierService.updateDossier(dossier);
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    DossiersGet();
    setNotify({
      isOpen: true,
      message: "Enregistré avec Succès",
      type: "success",
    });
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dossierService.deleteDossier(id);
    DossiersGet();
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        <Typography variant="h6" className={classes.listTitle}>
          Liste des Dossiers
        </Typography>
        <Toolbar>
          <Controls.Input
            label="Recherche Dossiers"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Link to="/menudossier/create">
            <Controls.Button
              text="Nouveau"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
            />
          </Link>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id_dossier} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  {item.id_dossier}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {item.nom_personne}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {item.prenom_personne}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {item.sexe_personne}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {item.nom_nationalite}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {item.nom_composition_familliale}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Controls.ActionButton
                    color="primary.light"
                    onClick={() => UpdateDossier(item.id_dossier)}
                  >
                    <EditOutlinedIcon fontSize="small" />
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    color="secondary.light"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Voullez-vous supprimer cet enregistrement ?",
                        subTitle: "Cette opération est irréversible",
                        onConfirm: () => {
                          onDelete(item.id);
                        },
                      });
                    }}
                  >
                    <DeleteOutlined fontSize="small" />
                  </Controls.ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      <Popup
        title={popupTitle}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <DossierForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
