import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useLocation,
  HashRouter,
  Redirect,
} from "react-router-dom";

import "./css/style.scss";

import { focusHandling } from "cruip-js-toolkit";
import "./charts/ChartjsConfig";

// Import pages
import AppRoutes from "./appRoutes";
import { useDispatch } from "react-redux";
import { fetchDataAPI } from "./helpers/helper";
import { SIDE_NAV_URL } from "./constant";
import { setSideNav, setRoutes } from "./actions";

import Login from "./pages/Connexion";

const getJWT = () => {
  return (Headers["authorization"] = localStorage.getItem("jwt_token"));
};

const isLoggedIn = () => {
  let is_logged_in =
    getJWT() !== null &&
    getJWT() !== "null" &&
    getJWT() !== undefined &&
    getJWT() !== "undefined";
  return is_logged_in;
};

function App() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, [location.pathname]); // triggered on route change

  const onAppLoad = () => {
    // @desc fetching sideNav and routes api on app load
    fetchDataAPI(SIDE_NAV_URL).then((response) => {
      if (response.success) {
        const routes = response.result.map((data) => data.fils).flat(Infinity);
        dispatch(setRoutes(routes));
        dispatch(setSideNav(response.result));
      }
    });
  };

  let appRoute;
  if (isLoggedIn()) {
    appRoute = <Route path="/" component={AppRoutes}></Route>;
  } else {
    appRoute = (
      <>
        <Redirect to="/login" />
        <Route path="/login" component={Login}></Route>
      </>
    );
  }

  useEffect(() => {
    onAppLoad();
  }, []);

  return (
    <>
      <HashRouter>
        <Switch>
          {/* {routeComponents} */}
          {appRoute}
        </Switch>
      </HashRouter>
    </>
  );
}

export default App;
