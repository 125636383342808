import { combineReducers } from "redux";
import * as actionType from '../actions'
import { reducer as formReducer } from 'redux-form';
import { i18nReducer } from 'redux-react-i18n';

const commonReducerInitialState = {
    loading: false,
    notSuccess: false,
    success: false
}

const navData = (state = [], { type, data }) => {
    switch (type) {
        case actionType.SET_SIDENAV:
            return data
        default:
            return state
    }
}

const routes = (state = [], { type, routes }) => {
    switch (type) {
        case actionType.SET_ROUTES:
            return routes
        default:
            return state
    }
}

const loader = (state = false, { type, data }) => {
    switch (type) {
        case actionType.SET_LOADER:
            return data
        default:
            return state
    }
}

const commonReducer = (state = commonReducerInitialState, action) => {
    switch (action.type) {
        case actionType.SET_LOADER:
            return {
                ...state,
                loading: action.loading
            }
        case actionType.SET_FAILED_MSG:
            return {
                ...state,
                notSuccess: action.notSuccess
            }
        case actionType.SET_SUCCESS_MSG:
            return {
                ...state,
                success: action.Success
            }
        default:
            return state
    }
}

const reducers = combineReducers({
    navData,
    form: formReducer,
    i18nReducer,
    commonReducer,
    loader,
    routes
});


export default reducers;