import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import routes from "./routes/routes";
import { findIndex } from "./helpers/helper";

import Dashboard from "./pages/Dashboard";
import Dossiers from "./pages/Dossiers/Dossiers";
import DossierDetails from "./pages/Dossiers/DossierDetails";

import SideBarContainer from "./containers/SideBarContainer";
import Header from "./partials/Header";
import LoaderContainer from "./containers/LoaderContainer";

const AppRoutes = () => {
  const _routes = useSelector((state) => state.routes);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <div className="flex h-screen overflow-hidden">
        <LoaderContainer />
        <SideBarContainer />
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <Switch>
            <Route exact path="/" component={Dashboard} />
            {/* Dynamics Routes */}
            {_routes.map((dynamicRoute, index) => {
              return (
                <Route
                  path={`/${dynamicRoute.route}/:id`}
                  component={
                    routes[
                      findIndex(routes, "componentName", dynamicRoute.component)
                    ].component
                  }
                  key={`sideNavRoute_${index}`}
                />
              );
            })}
            <Route exact path="/menudossier/list" component={Dossiers} />
            <Route
              exact
              path="/menudossier/update/:id"
              component={DossierDetails}
            />
            <Route
              exact
              path="/menudossier/create"
              component={DossierDetails}
            />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default AppRoutes;
