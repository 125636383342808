import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Transition from '../../utils/Transition';

function Notifications(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative inline-flex ml-3">
      <button
        ref={trigger}
        className={`w-8 h-8 flex items-center justify-center bg-gray-100 hover:bg-gray-200 transition duration-150 rounded-full ${dropdownOpen && 'bg-gray-200'}`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="sr-only">Notifications</span>
        <img width="24" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA2NCA2NDsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDY0IDY0IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiMxMzQ1NjM7fQo8L3N0eWxlPjxnPjxnIGlkPSJJY29uLUVudmVsb3BlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3OC4wMDAwMDAsIDIzMi4wMDAwMDApIj48cGF0aCBjbGFzcz0ic3QwIiBkPSJNLTIyLjUtMjEzLjJsLTEuOS0xLjlsLTE3LjYsMTcuNmMtMi4yLDIuMi01LjksMi4yLTguMSwwTC02Ny43LTIxNWwtMS45LDEuOWwxMy4xLDEzLjEgICAgIGwtMTMuMSwxMy4xbDEuOSwxLjlsMTMuMS0xMy4xbDIuNiwyLjZjMS42LDEuNiwzLjcsMi41LDUuOSwyLjVzNC4zLTAuOSw1LjktMi41bDIuNi0yLjZsMTMuMSwxMy4xbDEuOS0xLjlsLTEzLjEtMTMuMSAgICAgTC0yMi41LTIxMy4yIiBpZD0iRmlsbC0zIi8+PHBhdGggY2xhc3M9InN0MCIgZD0iTS0yNi4yLTE4MS42aC0zOS41Yy0yLjMsMC00LjItMS45LTQuMi00LjJ2LTI4LjJjMC0yLjMsMS45LTQuMiw0LjItNC4yaDM5LjUgICAgIGMyLjMsMCw0LjIsMS45LDQuMiw0LjJ2MjguMkMtMjItMTgzLjUtMjMuOS0xODEuNi0yNi4yLTE4MS42TC0yNi4yLTE4MS42eiBNLTY1LjgtMjE1LjVjLTAuOCwwLTEuNCwwLjYtMS40LDEuNHYyOC4yICAgICBjMCwwLjgsMC42LDEuNCwxLjQsMS40aDM5LjVjMC44LDAsMS40LTAuNiwxLjQtMS40di0yOC4yYzAtMC44LTAuNi0xLjQtMS40LTEuNEgtNjUuOEwtNjUuOC0yMTUuNXoiIGlkPSJGaWxsLTQiLz48L2c+PC9nPjwvc3ZnPg=="/>
        <div className="absolute top-0 right-0 w-2.5 h-2.5 bg-red-500 border-2 border-white rounded-full"></div>
      </button>

      <Transition
        className="origin-top-right z-10 absolute top-full right-0 -mr-48 sm:mr-0 min-w-80 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="text-xs font-semibold text-gray-400 uppercase pt-1.5 pb-2 px-4">Mail Notifications</div>
          <ul>
          {
              props.MailList && props.MailList.map((res, index) => {
                if(res.libelle === 'Mail'){
                  return(
                    res.fils && res.fils.map((res,index) => {
                      return(
                      <li className="border-b border-gray-200 last:border-0" key={index}>
                        <Link
                          className="block py-2 px-4 hover:bg-gray-50"
                          to={res.href}
                          
                        >
                          <span className="block text-sm mb-2">📣 <span className="font-medium text-gray-800">{res.libelle}</span></span>
                          {/* <span className="block text-xs font-medium text-gray-400">Feb 12, 2021</span> */}
                        </Link>
                      </li>
                      )
                    })
                  )
                }
              })
            }
          </ul>
        </div>
      </Transition>
    </div>
  )
}

export default Notifications;