import React, { useState, useEffect } from "react";
import { Grid, Card, CardHeader, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import * as dossierService from "../../helpers/helper";

import { getHeadLabel, getFieldsInitValues } from "./helper";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(3),
    display: "block",
  },
  formLabel: {
    marginBottom: theme.spacing(1),
    paddingTop: 10,
  },
  button: {
    marginRight: theme.spacing(2),
  },
  cardHeader: {
    backgroundColor: "#9ac2e6",
    padding: "5px 16px",
  },
}));

const genderItems = [
  { id: "H", title: "Homme" },
  { id: "F", title: "Femme" },
];

const yesNoItems = [
  { id: "OUI", title: "OUI" },
  { id: "NON", title: "NON" },
];

const noneYesNoItems = [
  { id: "", title: "Aucune Info" },
  { id: "OUI", title: "OUI" },
  { id: "NON", title: "NON" },
];

const initialFValues = getFieldsInitValues();

export default function DossierForm(props) {
  const classes = useStyles();
  const { addOrEdit } = props;
  const [recordForEdit, setRecordForEdit] = useState(props.recordForEdit);
  const [nationalites, setNationalites] = useState([]);
  const [originesDemographiques, setOriginesDemographiques] = useState([]);
  const [typesCarteIdentite, setTypesCarteIdentite] = useState([]);
  const [situationsProfessionnelles, setSituationsProfessionnelles] = useState(
    []
  );
  const [niveauxScolaires, setNiveauxScolaires] = useState([]);
  const [ressources, setRessources] = useState([]);
  const [droitsParental, setDroitsParental] = useState([]);
  const [statutsHue, setStatutsHue] = useState([]);
  const [composFamilliales, setComposFamilliales] = useState([]);
  const [habitats, setHabitats] = useState([]);
  const [selectedHabitatOpenplus, setSelectedHabitatOpenplus] = useState(null);
  const [situationsFamilliales, setSituationsFamilliales] = useState([]);
  const [centres, setCentres] = useState([]);
  const [instructeursDemande, setInstructeursDemande] = useState([]);
  const [orientationsSiao, setOrientationsSiao] = useState([]);
  const [motifsDemande, setMotifsDemande] = useState([]);
  const [partenaires, setPartenaires] = useState([]);
  const [typeAccompagnements, setTypeAccompagnements] = useState([]);
  const [motifsSortie, setMotifsSortie] = useState([]);
  const [selectedMotifSortieOpenplus, setSelectedMotifSortieOpenplus] =
    useState(null);
  const [orientationsSortie, setOrientationsSortie] = useState([]);
  const [commissions, setCommissions] = useState([]);
  const [logementsAutonomes, setLogementsAutonomes] = useState([]);
  const [comportementsPersonne, setComportementsPersonne] = useState([]);
  const [typesHebergement, setTypesHebergement] = useState([]);
  const [statutsDossier, setStatutsDossier] = useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("nom_personne" in fieldValues)
      temp.nom_personne = fieldValues.nom_personne
        ? ""
        : "Prière de renseigner le Nom.";
    if ("prenom_personne" in fieldValues)
      temp.prenom_personne = fieldValues.prenom_personne
        ? ""
        : "Prière de renseigner le Prénom.";
    if ("id_nationalite" in fieldValues)
      temp.id_nationalite =
        fieldValues.id_nationalite.length !== 0
          ? ""
          : "La Nationalité est requise.";
    if ("id_situation_professionnelle_a_arrivee" in fieldValues)
      temp.id_situation_professionnelle_a_arrivee =
        fieldValues.id_situation_professionnelle_a_arrivee.length != 0
          ? ""
          : "Prière de sélectionner une situation.";
    if ("id_ressource_a_arrivee" in fieldValues)
      temp.id_ressource_a_arrivee =
        fieldValues.id_ressource_a_arrivee.length != 0
          ? ""
          : "Prière de sélectionner une ressource.";
    if ("id_niveau_scolaire" in fieldValues)
      temp.id_niveau_scolaire =
        fieldValues.id_niveau_scolaire.length != 0
          ? ""
          : "Prière de sélectionner un niveau scolaire.";
    if ("id_statut_hue" in fieldValues)
      temp.id_statut_hue =
        fieldValues.id_statut_hue.length != 0
          ? ""
          : "Prière de sélectionner un Statut.";
    if ("id_composition_familliale" in fieldValues)
      temp.id_composition_familliale =
        fieldValues.id_composition_familliale.length != 0
          ? ""
          : "Prière de sélectionner une Composition.";
    if ("id_habita_accueilli" in fieldValues)
      temp.id_habita_accueilli =
        fieldValues.id_habita_accueilli.length != 0
          ? ""
          : "Prière de sélectionner un Habitat.";
    if ("id_situation_familliale" in fieldValues)
      temp.id_situation_familliale =
        fieldValues.id_situation_familliale.length != 0
          ? ""
          : "Prière de sélectionner la Situation familliale.";
    if ("id_centre" in fieldValues)
      temp.id_centre =
        fieldValues.id_centre.length != 0
          ? ""
          : "Prière de sélectionner un Centre.";
    if ("radiopounon12mois_dossier" in fieldValues)
      temp.radiopounon12mois_dossier = fieldValues.radiopounon12mois_dossier
        ? ""
        : "Ce champs est requis.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  useEffect(() => {
    //NationalitesGet();
    setRecordForEdit(props.recordForEdit);
    if (props.recordForEdit != null)
      setValues({
        ...props.recordForEdit,
      });
  }, [props.recordForEdit]);

  useEffect(() => {
    NationalitesGet();
    OriginesGeographiquesGet();
    TypesCarteIdentiteGet();
    SituationsProfessionnellesGet();
    ResourcesGet();
    NiveauxScolairesGet();
    DroitsParentalGet();
    StatutsHueGet();
    ComposFamillialesGet();
    HabitatsGet();
    SituationsFamillialesGet();
    CentresGet();
    InstructeursDemandeGet();
    OrientationsSiaoGet();
    MotifsDemandeGet();
    PartenairesGet();
    TypeAccompagnementsGet();
    MotifsSortieGet();
    OrientationsSortieGet();
    CommissionsGet();
    LogementsAutonomesGet();
    ComportementsPersonneGet();
    TypesHebergementGet();
    StatutsDossierGet();
  }, []);

  const NationalitesGet = () => {
    dossierService.getNationalitesCollection().then((result) => {
      setNationalites(result);
    });
  };

  const OriginesGeographiquesGet = () => {
    dossierService.getOriginesGeographiquesCollection().then((result) => {
      setOriginesDemographiques(result);
    });
  };

  const TypesCarteIdentiteGet = () => {
    dossierService.getTypesCarteIdentitesCollection().then((result) => {
      setTypesCarteIdentite(result);
    });
  };

  const SituationsProfessionnellesGet = () => {
    dossierService.getSituationsProfessionnellesCollection().then((result) => {
      setSituationsProfessionnelles(result);
    });
  };

  const ResourcesGet = () => {
    dossierService.getResourcesCollection().then((result) => {
      setRessources(result);
    });
  };

  const NiveauxScolairesGet = () => {
    dossierService.getNiveauxScolairesCollection().then((result) => {
      setNiveauxScolaires(result);
    });
  };

  const DroitsParentalGet = () => {
    dossierService.getDroitsParentalCollection().then((result) => {
      setDroitsParental(result);
    });
  };

  const StatutsHueGet = () => {
    dossierService.getStatutsHueCollection().then((result) => {
      setStatutsHue(result);
    });
  };

  const ComposFamillialesGet = () => {
    dossierService.getCompositionsFamillialesCollection().then((result) => {
      setComposFamilliales(result);
    });
  };

  const HabitatsGet = () => {
    dossierService.getHabitatsCollection().then((result) => {
      setHabitats(result);
    });
  };

  const SituationsFamillialesGet = () => {
    dossierService.getSituationsFamillialesCollection().then((result) => {
      setSituationsFamilliales(result);
    });
  };

  const CentresGet = () => {
    dossierService.getCentresCollection().then((result) => {
      setCentres(result);
    });
  };

  const InstructeursDemandeGet = () => {
    dossierService.getInstructeursDemandeCollection().then((result) => {
      setInstructeursDemande(result);
    });
  };

  const OrientationsSiaoGet = () => {
    dossierService.getOrientationsSiaoCollection().then((result) => {
      setOrientationsSiao(result);
    });
  };

  const MotifsDemandeGet = () => {
    dossierService.getMotifsDemandeCollection().then((result) => {
      setMotifsDemande(result);
    });
  };

  const PartenairesGet = () => {
    dossierService.getPartenairesCollection().then((result) => {
      setPartenaires(result);
    });
  };

  const TypeAccompagnementsGet = () => {
    dossierService.getTypesAccompagnementCollection().then((result) => {
      setTypeAccompagnements(result);
    });
  };

  const MotifsSortieGet = () => {
    dossierService.getMotifsSortieCollection().then((result) => {
      setMotifsSortie(result);
    });
  };

  const OrientationsSortieGet = () => {
    dossierService.getOrientationsSortieCollection().then((result) => {
      setOrientationsSortie(result);
    });
  };

  const CommissionsGet = () => {
    dossierService.getCommissionCollection().then((result) => {
      setCommissions(result);
    });
  };

  const LogementsAutonomesGet = () => {
    dossierService.getLogementAutonomeCollection().then((result) => {
      setLogementsAutonomes(result);
    });
  };

  const ComportementsPersonneGet = () => {
    dossierService.getComportementPersonneCollection().then((result) => {
      setComportementsPersonne(result);
    });
  };

  const TypesHebergementGet = () => {
    dossierService.getTypesHebergementCollection().then((result) => {
      setTypesHebergement(result);
    });
  };

  const StatutsDossierGet = () => {
    dossierService.getStatutsDossierCollection().then((result) => {
      setStatutsDossier(result);
    });
  };

  const handleHabitatSelectChange = (e) => {
    handleInputChange(e);
    if (e.target.value) {
      let rest = habitats.find((obj) => obj.id === e.target.value);
      setSelectedHabitatOpenplus(rest.openplus);
    } else {
      setSelectedHabitatOpenplus(null);
    }
  };

  const habitatPlusField = () => {
    if (selectedHabitatOpenplus == "Y") {
      return (
        <Controls.Input
          label={getHeadLabel("champplus_habitataccueilli_dossier")}
          name="champplus_habitataccueilli_dossier"
          value={values.champplus_habitataccueilli_dossier}
          onChange={handleInputChange}
          error={errors.champplus_habitataccueilli_dossier}
        />
      );
    } else {
      return (
        <Controls.Input
          disabled
          label={getHeadLabel("champplus_habitataccueilli_dossier")}
          name="champplus_habitataccueilli_dossier"
          value={values.champplus_habitataccueilli_dossier}
          onChange={handleInputChange}
          error={errors.champplus_habitataccueilli_dossier}
        />
      );
    }
  };

  const handleMotifSortieSelectChange = (e) => {
    handleInputChange(e);
    if (e.target.value) {
      let res = motifsSortie.find((obj) => obj.id === e.target.value);
      setSelectedMotifSortieOpenplus(res.openplus);
    } else {
      setSelectedMotifSortieOpenplus(null);
    }
  };

  const motifSortiePlusField = () => {
    if (selectedMotifSortieOpenplus == "Y") {
      return (
        <Controls.Input
          label={getHeadLabel("champplus_motif_sortie_dossier")}
          name="champplus_motif_sortie_dossier"
          value={values.champplus_motif_sortie_dossier}
          onChange={handleInputChange}
          error={errors.champplus_motif_sortie_dossier}
        />
      );
    } else {
      return (
        <Controls.Input
          disabled
          label={getHeadLabel("champplus_motif_sortie_dossier")}
          name="champplus_motif_sortie_dossier"
          value={values.champplus_motif_sortie_dossier}
          onChange={handleInputChange}
          error={errors.champplus_motif_sortie_dossier}
        />
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          titleTypographyProps={{ variant: "subtitle2" }}
          subheaderTypographyProps={{
            variant: "subtitle2",
            align: "left",
          }}
          subheader="Informations sur la Personne"
          className={classes.cardHeader}
        ></CardHeader>
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <Controls.Input
                name="nom_personne"
                label={getHeadLabel("nom_personne")}
                value={values.nom_personne}
                onChange={handleInputChange}
                error={errors.nom_personne}
              />
              <Controls.Input
                label={getHeadLabel("prenom_personne")}
                name="prenom_personne"
                value={values.prenom_personne}
                onChange={handleInputChange}
                error={errors.prenom_personne}
              />
              <Controls.Select
                name="id_nationalite"
                label={getHeadLabel("nom_nationalite")}
                value={values.id_nationalite}
                onChange={handleInputChange}
                options={nationalites}
                error={errors.id_nationalite}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                label={getHeadLabel("age_personne")}
                name="age_personne"
                value={values.age_personne}
                onChange={handleInputChange}
                error={errors.age_personne}
              />
              <Controls.RadioGroup
                name="sexe_personne"
                label={getHeadLabel("sexe_personne")}
                value={values.sexe_personne}
                onChange={handleInputChange}
                items={genderItems}
              />
              <Controls.Select
                name="id_origine_demographique"
                label={getHeadLabel("nom_origine_demographique")}
                value={values.id_origine_demographique}
                onChange={handleInputChange}
                options={originesDemographiques}
                error={errors.id_origine_demographique}
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.RadioGroup
                name="id_type_carte_identite"
                label={getHeadLabel("nom_type_carte_identite")}
                value={values.id_type_carte_identite}
                onChange={handleInputChange}
                items={typesCarteIdentite}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.DatePicker
                name="date_validite_type_carte_identite"
                label={getHeadLabel("date_validite_type_carte_identite")}
                value={values.date_validite_type_carte_identite}
                onChange={handleInputChange}
              />
              <Controls.Select
                name="id_situation_professionnelle_a_arrivee"
                label={getHeadLabel("nom_situation_professionnelle_a_arrivee")}
                value={values.id_situation_professionnelle_a_arrivee}
                onChange={handleInputChange}
                options={situationsProfessionnelles}
                error={errors.id_situation_professionnelle_a_arrivee}
              />
              <Controls.Select
                name="id_situation_professionnelle_a_sortie"
                label={getHeadLabel("nom_situation_professionnelle_a_sortie")}
                value={values.id_situation_professionnelle_a_sortie}
                onChange={handleInputChange}
                options={situationsProfessionnelles}
                error={errors.id_situation_professionnelle_a_sortie}
              />
              <Controls.Select
                name="id_ressource_a_arrivee"
                label={getHeadLabel("nom_ressource_a_arrivee")}
                value={values.id_ressource_a_arrivee}
                onChange={handleInputChange}
                options={ressources}
                error={errors.id_ressource_a_arrivee}
              />
              <Controls.Select
                name="id_droit_parental"
                label={getHeadLabel("nom_droit_parental")}
                value={values.id_droit_parental}
                onChange={handleInputChange}
                options={droitsParental}
                error={errors.id_droit_parental}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                label={getHeadLabel("montant_prestation")}
                name="montant_prestation"
                value={values.montant_prestation}
                onChange={handleInputChange}
                error={errors.montant_prestation}
              />
              <Controls.Select
                name="id_ressource_a_sortie"
                label={getHeadLabel("nom_ressource_a_sortie")}
                value={values.id_ressource_a_sortie}
                onChange={handleInputChange}
                options={ressources}
                error={errors.id_ressource_a_sortie}
              />
              <Controls.Input
                label={getHeadLabel("montant_prestation_sortie")}
                name="montant_prestation_sortie"
                value={values.montant_prestation_sortie}
                onChange={handleInputChange}
                error={errors.montant_prestation_sortie}
              />
              <Controls.Select
                name="id_niveau_scolaire"
                label={getHeadLabel("nom_niveau_scolaire")}
                value={values.id_niveau_scolaire}
                onChange={handleInputChange}
                options={niveauxScolaires}
                error={errors.id_niveau_scolaire}
              />
              <Controls.Select
                name="id_statut_hue"
                label={getHeadLabel("nom_statut_hue")}
                value={values.id_statut_hue}
                onChange={handleInputChange}
                options={statutsHue}
                error={errors.id_statut_hue}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container></Grid>
      <Card>
        <CardHeader
          titleTypographyProps={{ variant: "subtitle2" }}
          subheaderTypographyProps={{
            variant: "subtitle2",
            align: "left",
          }}
          subheader="Informations sur le Dossier"
          className={classes.cardHeader}
        ></CardHeader>
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <Controls.DatePicker
                name="datearrive"
                label={getHeadLabel("datearrive")}
                value={values.datearrive}
                onChange={handleInputChange}
              />
              <Controls.DatePicker
                name="dateturneover"
                label={getHeadLabel("dateturneover")}
                value={values.dateturneover}
                onChange={handleInputChange}
              />
              <Controls.Select
                name="id_composition_familliale"
                label={getHeadLabel("nom_composition_familliale")}
                value={values.id_composition_familliale}
                onChange={handleInputChange}
                options={composFamilliales}
                error={errors.id_composition_familliale}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.TimePicker
                label={getHeadLabel("heurearrive")}
                name="heurearrive"
                value={values.heurearrive}
                onChange={handleInputChange}
                error={errors.heurearrive}
              />
              <Controls.Input
                label={getHeadLabel("nombre_enfant_dossier")}
                name="nombre_enfant_dossier"
                value={values.nombre_enfant_dossier}
                onChange={handleInputChange}
                error={errors.nombre_enfant_dossier}
              />
              <Controls.Input
                label={getHeadLabel("nombre_aminaux_dossier")}
                name="nombre_aminaux_dossier"
                value={values.nombre_aminaux_dossier}
                onChange={handleInputChange}
                error={errors.nombre_aminaux_dossier}
              />
            </Grid>
            <Grid item xs={6}>
              {" "}
            </Grid>
            <Grid item xs={6}>
              {" "}
            </Grid>
            <Grid item xs={6}>
              <Controls.Select
                name="id_habita_accueilli"
                label={getHeadLabel("nom_habita_accueilli")}
                value={values.id_habita_accueilli}
                onChange={handleHabitatSelectChange}
                options={habitats}
                error={errors.id_habita_accueilli}
              />
            </Grid>
            <Grid item xs={6}>
              {habitatPlusField()}
            </Grid>
            <Grid item xs={6}>
              <Controls.Select
                name="id_situation_familliale"
                label={getHeadLabel("nom_situation_familliale")}
                value={values.id_situation_familliale}
                onChange={handleInputChange}
                options={situationsFamilliales}
                error={errors.id_situation_familliale}
              />
              <Controls.Input
                label={getHeadLabel("nom_motif_refus_struture")}
                name="nom_motif_refus_struture"
                value={values.nom_motif_refus_struture}
                onChange={handleInputChange}
                error={errors.nom_motif_refus_struture}
              />
              <Controls.Select
                name="id_orientation_sortie"
                label={getHeadLabel("nom_orientation_sortie")}
                value={values.id_orientation_sortie}
                onChange={handleInputChange}
                options={orientationsSortie}
                error={errors.id_orientation_sortie}
              />
              <Controls.Select
                name="id_centre"
                label={getHeadLabel("nom_centre")}
                value={values.id_centre}
                onChange={handleInputChange}
                options={centres}
                error={errors.id_centre}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Select
                name="id_instructeur_demande"
                label={getHeadLabel("nom_instructeur_demande")}
                value={values.id_instructeur_demande}
                onChange={handleInputChange}
                options={instructeursDemande}
                error={errors.id_instructeur_demande}
              />
              <Controls.Select
                name="id_oirentation_siao"
                label={getHeadLabel("nom_oirentation_siao")}
                value={values.id_oirentation_siao}
                onChange={handleInputChange}
                options={orientationsSiao}
                error={errors.id_oirentation_siao}
              />
              <Controls.Select
                name="id_motif_demande"
                label={getHeadLabel("nom_motif_demande")}
                value={values.id_motif_demande}
                onChange={handleInputChange}
                options={motifsDemande}
                error={errors.id_motif_demande}
              />
              <Controls.Select
                name="id_partenaire_sollicite"
                label={getHeadLabel("nom_partenaire_sollicite")}
                value={values.id_partenaire_sollicite}
                onChange={handleInputChange}
                options={partenaires}
                error={errors.id_partenaire_sollicite}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Controls.Select
                name="id_type_accompagnement_demande"
                label={getHeadLabel("nom_type_accompagnement_demande")}
                value={values.id_type_accompagnement_demande}
                onChange={handleInputChange}
                options={typeAccompagnements}
                error={errors.id_type_accompagnement_demande}
              />
              <Controls.Select
                name="id_type_accompagnement_realise"
                label={getHeadLabel("nom_type_accompagnement_realise")}
                value={values.id_type_accompagnement_realise}
                onChange={handleInputChange}
                options={typeAccompagnements}
                error={errors.id_type_accompagnement_realise}
              />
              <Controls.Input
                label={getHeadLabel("nom_logement_social")}
                name="nom_logement_social"
                value={values.nom_logement_social}
                onChange={handleInputChange}
                error={errors.nom_logement_social}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                label={getHeadLabel("precision_type_accompagnement")}
                name="precision_type_accompagnement"
                value={values.precision_type_accompagnement}
                onChange={handleInputChange}
                error={errors.precision_type_accompagnement}
              />
              <Controls.Input
                label={getHeadLabel("precision_type_accompagnement_realise")}
                name="precision_type_accompagnement_realise"
                value={values.precision_type_accompagnement_realise}
                onChange={handleInputChange}
                error={errors.precision_type_accompagnement_realise}
              />
              <Controls.Input
                label={getHeadLabel("nom_nature_prise_en_charge")}
                name="nom_nature_prise_en_charge"
                value={values.nom_nature_prise_en_charge}
                onChange={handleInputChange}
                error={errors.nom_nature_prise_en_charge}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Select
                name="id_motif_sortie"
                label={getHeadLabel("nom_motif_sortie")}
                value={values.id_motif_sortie}
                onChange={handleMotifSortieSelectChange}
                options={motifsSortie}
                error={errors.id_motif_sortie}
              />
            </Grid>
            <Grid item xs={6}>
              {motifSortiePlusField()}
            </Grid>
            <Grid item xs={6}>
              <Controls.RadioGroup
                name="personne_oriente_par_siao_dossier"
                label={getHeadLabel("personne_oriente_par_siao_dossier")}
                value={values.personne_oriente_par_siao_dossier}
                onChange={handleInputChange}
                items={yesNoItems}
              />
              <Controls.Select
                name="id_affection_commission"
                label={getHeadLabel("nom_affection_commission")}
                value={values.id_affection_commission}
                onChange={handleInputChange}
                options={commissions}
                error={errors.id_affection_commission}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.RadioGroup
                name="personne_oriente_vers_siao_dossier"
                label={getHeadLabel("personne_oriente_vers_siao_dossier")}
                value={values.personne_oriente_vers_siao_dossier}
                onChange={handleInputChange}
                items={yesNoItems}
              />
              <Controls.DatePicker
                name="dateenreg"
                label={getHeadLabel("dateenreg")}
                value={values.dateenreg}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.RadioGroup
                name="id_logement_automone"
                label={getHeadLabel("nom_logement_automone")}
                value={values.id_logement_automone}
                onChange={handleInputChange}
                items={logementsAutonomes}
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.Input
                label={getHeadLabel("decision_structure")}
                name="decision_structure"
                value={values.decision_structure}
                onChange={handleInputChange}
                error={errors.decision_structure}
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.Input
                label={getHeadLabel("decision_demandeur")}
                name="decision_demandeur"
                value={values.decision_demandeur}
                onChange={handleInputChange}
                error={errors.decision_demandeur}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.RadioGroup
                name="radiopounon12mois_dossier"
                label={getHeadLabel("radiopounon12mois_dossier")}
                value={values.radiopounon12mois_dossier}
                onChange={handleInputChange}
                items={yesNoItems}
              />
              <Controls.RadioGroup
                name="radioeffectue_dossier"
                label={getHeadLabel("radioeffectue_dossier")}
                value={values.radioeffectue_dossier}
                onChange={handleInputChange}
                items={yesNoItems}
              />
              <Controls.RadioGroup
                name="depistage_hepatite_4dernier_mois"
                label={getHeadLabel("depistage_hepatite_4dernier_mois")}
                value={values.depistage_hepatite_4dernier_mois}
                onChange={handleInputChange}
                items={noneYesNoItems}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.RadioGroup
                name="radiopropose_dossier"
                label={getHeadLabel("radiopropose_dossier")}
                value={values.radiopropose_dossier}
                onChange={handleInputChange}
                items={yesNoItems}
              />
              <Controls.RadioGroup
                name="probeme_sante_dossier"
                label={getHeadLabel("probeme_sante_dossier")}
                value={values.probeme_sante_dossier}
                onChange={handleInputChange}
                items={yesNoItems}
              />
              <Controls.RadioGroup
                name="ehm_propose"
                label={getHeadLabel("ehm_propose")}
                value={values.ehm_propose}
                onChange={handleInputChange}
                items={noneYesNoItems}
              />
              <Controls.RadioGroup
                name="ehm_realise"
                label={getHeadLabel("ehm_realise")}
                value={values.ehm_realise}
                onChange={handleInputChange}
                items={noneYesNoItems}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.RadioGroup
                name="personne_enceinte"
                label={getHeadLabel("personne_enceinte")}
                value={values.personne_enceinte}
                onChange={handleInputChange}
                items={noneYesNoItems}
              />
              <Controls.Select
                name="comportement_personne"
                label={getHeadLabel("comportement_personne")}
                value={values.comportement_personne}
                onChange={handleInputChange}
                options={comportementsPersonne}
                error={errors.comportement_personne}
              />
              <Controls.RadioGroup
                name="statut_dossier"
                label={getHeadLabel("statut_dossier")}
                value={values.statut_dossier}
                onChange={handleInputChange}
                items={statutsDossier}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.RadioGroup
                name="vue_par_travailleur_social"
                label={getHeadLabel("vue_par_travailleur_social")}
                value={values.vue_par_travailleur_social}
                onChange={handleInputChange}
                items={yesNoItems}
              />
              <Controls.Select
                name="type_hebergement"
                label={getHeadLabel("type_hebergement")}
                value={values.type_hebergement}
                onChange={handleInputChange}
                options={typesHebergement}
                error={errors.type_hebergement}
              />
              <Controls.RadioGroup
                name="fin_prise_en_charge"
                label={getHeadLabel("fin_prise_en_charge")}
                value={values.fin_prise_en_charge}
                onChange={handleInputChange}
                items={yesNoItems}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Controls.DatePicker
                name="datecloture"
                label={getHeadLabel("datecloture")}
                value={values.datecloture}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.TimePicker
                label={getHeadLabel("heuredepart")}
                name="heuredepart"
                value={values.heuredepart}
                onChange={handleInputChange}
                error={errors.heuredepart}
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.Input
                label={getHeadLabel("observation_dossier")}
                name="observation_dossier"
                value={values.observation_dossier}
                onChange={handleInputChange}
                error={errors.observation_dossier}
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.Input
                label={getHeadLabel("post_commission")}
                name="post_commission"
                value={values.post_commission}
                onChange={handleInputChange}
                error={errors.post_commission}
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.Input
                label={getHeadLabel("decision_commission")}
                name="decision_commission"
                value={values.decision_commission}
                onChange={handleInputChange}
                error={errors.decision_commission}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                label={getHeadLabel("listeattente_dossier")}
                name="listeattente_dossier"
                value={values.listeattente_dossier}
                onChange={handleInputChange}
                error={errors.listeattente_dossier}
              />
              <Controls.DatePicker
                name="dateentrecava"
                label={getHeadLabel("dateentrecava")}
                value={values.dateentrecava}
                onChange={handleInputChange}
              />
              <Controls.DatePicker
                name="datesortiecava"
                label={getHeadLabel("datesortiecava")}
                value={values.datesortiecava}
                onChange={handleInputChange}
              />
              <Controls.Input
                label={getHeadLabel("nom_orientation_cava")}
                name="nom_orientation_cava"
                value={values.nom_orientation_cava}
                onChange={handleInputChange}
                error={errors.nom_orientation_cava}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.DatePicker
                name="datesortiecava"
                label={getHeadLabel("datesortiecava")}
                value={values.datesortiecava}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="vue_par_agent_dossier"
                label={getHeadLabel("vue_par_agent_dossier")}
                value={values.vue_par_agent_dossier}
                onChange={handleInputChange}
              />
              <Controls.Input
                label={getHeadLabel("champplus_instructeur_dossier")}
                name="champplus_instructeur_dossier"
                value={values.champplus_instructeur_dossier}
                onChange={handleInputChange}
                error={errors.champplus_instructeur_dossier}
              />
              <Controls.Input
                label={getHeadLabel("text_orientation_siao_propose")}
                name="text_orientation_siao_propose"
                value={values.text_orientation_siao_propose}
                onChange={handleInputChange}
                error={errors.text_orientation_siao_propose}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </CardContent>
      </Card>

      <Grid container>
        <div>
          <Controls.Button type="submit" text="Valider" />
          <Controls.Button
            text="Réinitialiser"
            color="default"
            onClick={resetForm}
          />
        </div>
      </Grid>
    </Form>
  );
}
