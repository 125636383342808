import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const Loader = ({ isLoader }) => {
 
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default Loader