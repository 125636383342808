import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { useForm } from "react-hook-form";

import { handleLoginSubmit } from "../helpers/helper";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        DG - Entreprises
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const initialFormData = Object.freeze({
  login: "",
  password: "",
});

const authAPI = "api/api_user/authentification";

const LoginTab = (props) => {
  //const { classes } = this.props;
  const history = useHistory();
  const classes = useStyles();
  const [formData, setFormData] = useState(initialFormData);
  const [loginResult, setLoginResult] = useState({
    login: {
      failed: false,
      message: "",
    },
    password: {
      failed: false,
      message: "",
    },
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onChangeHandler = (e) => {
    setLoginResult({
      login: {
        failed: false,
        message: "",
      },
      password: {
        failed: false,
        message: "",
      },
    });
  };

  const submitHandler = (data, e) => {
    e.preventDefault();
    e.target.reset();
    setFormData(data);
    let url;

    // @desc Add new record to API
    url = `${authAPI}`;
    handleLoginSubmit(url, formData).then((res) => {
      if (res.result.sucess) {
        setLoginResult({
          login: {
            failed: false,
            message: "",
          },
          password: {
            failed: false,
            message: "",
          },
        });
        localStorage.setItem("jwt_token", res.result.token);
        history.push("/");
      } else {
        setLoginResult({
          login: {
            failed: true,
            message: "Login et/ou mot de passe incorrect",
          },
          password: {
            failed: true,
            message: "Login et/ou mot de passe incorrect",
          },
        });
      }
    });

    return;
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Connexion
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(submitHandler)}
        >
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="login"
            label="Login"
            name="login"
            onChange={onChangeHandler}
            {...register("login", { required: "Le Login est requis." })}
            error={Boolean(loginResult.login.failed)}
            helperText={loginResult.login?.message}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Mot de Passe"
            type="password"
            id="password"
            onChange={onChangeHandler}
            {...register("password", {
              required: "Prière de renseigner le mot de passe.",
            })}
            error={Boolean(loginResult.password.failed)}
            helperText={loginResult.password?.message}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Valider
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Mot de Passe oublié ?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Pas de compte ? Enregistrez-vous"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default LoginTab;
