import { connect } from 'react-redux';
import ListFormDyn from '../partials/form-list'

const mapStateToProps = (state) => {

    const {
        navData,
        routes
    } = state

    return {
        navData,
        routes
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        
    };
};

const SearchFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListFormDyn);

export default SearchFormContainer;
