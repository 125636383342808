import React, { useEffect } from 'react';
import WelcomeBanner from '../partials/dashboard/WelcomeBanner'; 

const Dashboard = () => {
  return (
    <div className="flex h-screen overflow-hidden">
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <WelcomeBanner mainTitle='Good afternoon, Dgentreprises 👋' subTitle='Here is what’s happening with your projects today:' />
          </div>
        </main>
      </div>
    </div>
  );
}
export default Dashboard;