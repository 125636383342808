import { connect } from 'react-redux';
import Sidebar from '../partials/Sidebar';

const mapStateToProps = (state) => {

    const {
        navData
    } = state

    return {
        navData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

const SideBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Sidebar);

export default SideBarContainer;
