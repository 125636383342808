import React, { useState, useEffect } from "react";
import SearchModal from "./header/SearchModal";
import Notifications from "./header/Notifications";
import Mail from "./header/Mail.js";
import UserMenu from "./header/UserMenu";
import axios from "axios";
import { Link } from "react-router-dom";

function Header({ sidebarOpen, setSidebarOpen }) {
  const getJWT = () => {
    return (Headers["authorization"] = localStorage.getItem("jwt_token"));
  };

  const isLoggedIn = () => {
    let is_logged_in =
      getJWT() !== null &&
      getJWT() !== "null" &&
      getJWT() !== undefined &&
      getJWT() !== "undefined";
    return is_logged_in;
  };

  const [getTopNavData, setTopNavData] = useState([]);

  const getTopNavItem = () => {
    const topmenuItemurl =
      "http://admin.dgentreprises.biz/api_form/nav_barre.php";
    const headerConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    axios
      .get(topmenuItemurl, headerConfig)
      .then((res) => {
        setTopNavData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTopNavItem();
  }, []);

  let userInfosLink;

  if (isLoggedIn()) {
    userInfosLink = <UserMenu userSetting={getTopNavData} />;
  } else {
    userInfosLink = (
      <Link
        className="font-medium text-sm text-grey-500 hover:text-indigo-600 flex items-center py-1 px-3"
        to="/login"
      >
        Connexion
      </Link>
    );
  }

  return (
    <header className="sticky top-0 bg-white border-b border-gray-200 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Header: Left side */}
          <div className="flex">
            {/* Hamburger button */}
            <button
              className="text-gray-500 hover:text-gray-600 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
          </div>

          {/* Header: Right side */}
          <div className="flex items-center">
            <SearchModal />
            <Mail MailList={getTopNavData} />
            <Notifications notificationsList={getTopNavData} />
            {/*  Divider */}
            <hr className="w-px h-6 bg-gray-200 mx-3" />
            {userInfosLink}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
