import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = (props) => {

    const { label, optionLists, onChangeHandler, name } = props



    return (
        <div className="col-span-6 sm:col-span-3" key={label}>
            <label className="block text-sm font-medium text-gray-700">{label}</label>
            <div className="mt-4 space-y-4">
                {
                    optionLists && Object.values(optionLists).map((checkBox, index) => {
                        return (
                            <div className="flex items-start" key={`checkBox-${index}`}>
                                <div className="flex items-center h-5">
                                    <input onChange={onChangeHandler} id={`${label}-${checkBox}`} name={name} type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label htmlFor={`${label}-${checkBox}`} className="font-medium text-gray-700">{checkBox}</label>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

Checkbox.propTypes = {
    label: PropTypes.string,
    optionLists: PropTypes.object,
}

export default Checkbox
