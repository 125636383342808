import { connect } from 'react-redux';
import Loader from '../components/Loader'

const mapStateToProps = (state) => {

    const {
        loader
    } = state

    return {
        isLoader: loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

const LoaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Loader);

export default LoaderContainer;
