import React, { useState } from 'react'
import PropTypes from 'prop-types'

const InputField = (props) => {

    const { type, label, required, onChangeHandler, name } = props

    return (
        <div className="col-span-6 sm:col-span-3" key={label}>
            <label htmlFor={label} className="block text-sm font-medium text-gray-700">{label}</label>
            <input onChange={onChangeHandler} required={required === 'ok' ? 'required' : ''} type={type} name={name} id={label} className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
        </div>
    )
}

InputField.propTypes = {
    label: PropTypes.string,
    required: PropTypes.string,
    type: PropTypes.string,
}

export default InputField