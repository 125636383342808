import React, { useState, useEffect } from "react";
import axios from "axios";
import WelcomeBanner from "./dashboard/WelcomeBanner";
import DynamicForm from "../components/DynamicForm";
import TableList from "../partials/datatable";
import { findIndex, fetchDataAPI } from "../helpers/helper";
import { useDispatch } from "react-redux";
import { setLoader } from "../actions";
import Button from "@mui/material/Button";

const FormDraw = (props) => {
  const dispatch = useDispatch();

  const { routes, match } = props;
  const [formData, setFormData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [addRecord, setAddRecord] = useState(false);
  const [updateRecord, setUpdateRecord] = useState(false);
  const [updateRecordID, setUpdateRecordID] = useState("");
  const [formDataSubmission, setFormDataSubmission] = useState({});

  const listingAPI =
    routes[findIndex(routes, "libelle", props.match.params.id)].listAPI;
  const formSubmissionAPI =
    routes[findIndex(routes, "libelle", props.match.params.id)].formAPI;

  useEffect(() => {
    fetchFormData();
    fetchTableData();
  }, [match.params.id]);

  const fetchFormData = () => {
    const url =
      routes[findIndex(routes, "libelle", props.match.params.id)].formSchema;
    dispatch(setLoader(true));
    fetchDataAPI(url).then((res) => {
      if (res.success) {
        dispatch(setLoader(false));
        addRecord
          ? setFormData(res.result.form)
          : setFormData(res.result.form.filter((data) => data.aff_recherche));
      }
    });
  };

  const fetchTableData = () => {
    dispatch(setLoader(true));
    const url =
      routes[findIndex(routes, "libelle", props.match.params.id)].listAPI;
    fetchDataAPI(url).then((res) => {
      if (res.success) setTableData(res);
      dispatch(setLoader(false));
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    e.target.reset();
    dispatch(setLoader(true));
    let jsonToQueryParam = new URLSearchParams(formDataSubmission).toString();
    let url;

    if (addRecord) {
      if (updateRecord) {
        // @desc update existing record

        url = `${tableData.result.TableAPI.updateRecord}/${updateRecordID}?${jsonToQueryParam}`;
        fetchDataAPI(url, null, "PUT").then((res) => {
          if (res.success) {
            // need to show confirmation to user that the record has been updated and redirect to current form listing
            alert("Details Has been updated");
            setAddRecord(false);
            setUpdateRecord(false);
            fetchTableData();
          }
          dispatch(setLoader(false));
        });

        return;
      }

      // @desc Add new record to API
      url = `${formSubmissionAPI}?${jsonToQueryParam}`;
      fetchDataAPI(url, null, "POST").then((res) => {
        if (res.success) {
          alert("New Record Has been Added");
          setAddRecord(false);
          fetchTableData();
        }
        dispatch(setLoader(false));
        // Need to show toast/Message for Updated Add
      });

      return;
    }

    // @desc Search record
    url = `${listingAPI}?${jsonToQueryParam}`;
    fetchDataAPI(url).then((res) => {
      if (res.success) {
        setTableData(res);
      }
      dispatch(setLoader(false));
    });
  };

  const onChangeHandler = (e) => {
    let tempObj = { ...formDataSubmission, [e.target.name]: e.target.value };
    setFormDataSubmission(tempObj);
  };

  const addRecordHandler = () => {
    setAddRecord(!addRecord);
  };

  const deleteRecordHandler = (id) => {
    dispatch(setLoader(true));
    const url = `${tableData.result.TableAPI.deleteRecord}/${id}`;
    fetchDataAPI(url).then((res) => {
      if (res.success) {
        alert("Enregistrement Supprimé");
        fetchTableData();
        dispatch(setLoader(false));
      }
    });
  };

  const updateRecordHandler = (id) => {
    setAddRecord(true);
    setUpdateRecord(true);
    setUpdateRecordID(id);
  };

  return (
    <React.Fragment>
      <WelcomeBanner
        innerClass={"inner-heading"}
        mainTitle={props.match.params.id}
      />
      <div className="col-span-12 text-right px-4 pt-5 bg-white sm:p-6">
        <Button variant="contained" onClick={addRecordHandler}>
          {addRecord ? "Back" : "Add"}
        </Button>
      </div>

      <DynamicForm
        submitHandler={submitHandler}
        onChangeHandler={onChangeHandler}
        formData={formData}
        submitButtonText={addRecord ? "Submit" : "Search"}
      />
      {!addRecord && (
        <TableList
          tableData={tableData.result}
          deleteRecordHandler={deleteRecordHandler}
          updateRecordHandler={updateRecordHandler}
        />
      )}
    </React.Fragment>
  );
};

export default FormDraw;
