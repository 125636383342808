import axios from "axios";
import Moment from "moment";
import { BASE_URL } from "../constant";

const header = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};

const dossierApi = "api/api_dossier/dossier";
const nationaliteApi = "api/api_param/nationalite";
const originegeographiqueApi = "api/api_param/origine_geographique";
const typecarteidentiteApi = "api/api_param/type_carte";
const situationProfessionnelleApi = "api/api_param/situtation_professionnelle";
const ressourceApi = "api/api_param/ressource";
const niveauScolaireApi = "api/api_param/niveau_scolaire";
const droitParentalApi = "api/api_param/droit_parental";
const statutHueApi = "api/api_param/statut_hue";
const compositionFamillialeApi = "api/api_param/composition_familliale";
const habitatApi = "api/api_param/habitat";
const situationFamillialeApi = "api/api_param/situation_familliale";
const centreApi = "api/api_param/centre";
const instructeurDemandeApi = "api/api_param/instructeur_demande";
const orientationSiaoApi = "api/api_param/orientation_siao";
const motifDemandeApi = "api/api_param/motif_demande";
const partenaireApi = "api/api_param/partenaire";
const typeAccompagnementApi = "api/api_param/type_accompagnement";
const motifSortieApi = "api/api_param/motif_sortie";
const orientationSortieApi = "api/api_param/orientation_sortie";
const commissionApi = "api/api_param/commission";
const logementAutonomeApi = "api/api_param/logement_autonome";
const comportementPersonneApi = "api/api_param/comportement_personne";
const typeHebergementApi = "api/api_param/type_hebergement";

export const getDepartmentCollection = () => [
  { id: "1", title: "Development" },
  { id: "2", title: "Marketing" },
  { id: "3", title: "Accounting" },
  { id: "4", title: "HR" },
];

export const findIndex = (array, key, name) => {
  return array.findIndex((obj) => obj[key] === name);
};

export const fetchDataAPI = async (
  url = "",
  headerWithToken,
  method = "GET"
) => {
  const headerType = headerWithToken ?? header;
  return await axios(BASE_URL + url, {
    method: method,
    headers: headerType,
  })
    .then((response) => {
      return {
        success: true,
        apiAnswer: true,
        result: response.data,
      };
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const handleLoginSubmit = async (url, formValue) => {
  // store the states in the form data
  const loginFormData = new FormData();
  loginFormData.append("login", formValue.login);
  loginFormData.append("password", formValue.password);

  try {
    // make axios post request
    return await axios({
      method: "post",
      url: BASE_URL + url,
      data: loginFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        return {
          success: true,
          apiAnswer: true,
          result: response.data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          apiAnswer: true,
          error,
        };
      });
  } catch (error) {
    return error;
  }
};

export const dossierPost = async (url, formValue) => {
  // store the states in the form data
  const fd = new FormData();

  for (let property in formValue) {
    fd.append(property, formValue[property]);
  }

  try {
    // make axios post request
    return await axios({
      method: "post",
      url: BASE_URL + url,
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        return {
          success: true,
          apiAnswer: true,
          result: response.data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          apiAnswer: true,
          error,
        };
      });
  } catch (error) {
    console.log("unexpected erreur post dossier: ", error);
    return error;
  }
};

export const dossierPut = async (url, formValue) => {
  // store the states in the form data
  const fd = new FormData();

  for (let property in formValue) {
    fd.append(property, formValue[property]);
  }

  fd.append("_method", "PATCH");

  try {
    // make axios post request
    return await axios({
      method: "put",
      url: BASE_URL + url,
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        return {
          success: true,
          apiAnswer: true,
          result: response.data,
        };
      })
      .catch((error) => {
        console.log("erreur put dossier: ", error);
        return {
          success: false,
          apiAnswer: true,
          error,
        };
      });
  } catch (error) {
    console.log("unexpected erreur put dossier: ", error);
    return error;
  }
};

export const dossierPost_bkp = async (url, formValue) => {
  // store the states in the form data
  const fd = new FormData();
  for (let property in formValue) {
    fd.append(property, formValue[property]);
  }

  try {
    let finalUrl = BASE_URL + url;
    const requestType = "post";
    // make axios post request
    return await axios[requestType](finalUrl, fd)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("ERROR post dossier: ", error);
        return error;
      });
  } catch (error) {
    console.log("unexpected ERROR post dossier: ", error);
    return error;
  }
};

// DOSSIERS
export const getAllDossiers = async () => {
  const headerType = header;
  return await axios(BASE_URL + dossierApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((dossier) => formatDossier(dossier));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getOneDossier = async (id) => {
  const headerType = header;
  return await axios(BASE_URL + dossierApi + "/" + id, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      // result.tab[0]
      let dossier = response.data.tab[0];
      return formatDossier(dossier);
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

const formatDossier = (dossier) => {
  return {
    ...dossier,
    datecloture: formatDate(dossier.datecloture),
    dateenreg: formatDate(dossier.dateenreg),
    dateentrecava: formatDate(dossier.dateentrecava),
    datesortiecava: formatDate(dossier.datesortiecava),
    heurearrive: formatTime(dossier.heurearrive),
    heuredepart: formatTime(dossier.heuredepart),
    date_validite_type_carte_identite: formatDate(
      dossier.date_validite_type_carte_identite
        ? dossier.date_validite_type_carte_identite
        : null
    ),
    datearrive: formatDate(dossier.datearrive ? dossier.datearrive : null),
    dateturneover: formatDate(
      dossier.dateturneover ? dossier.dateturneover : null
    ),
  };
};

const formatDate = (string) => {
  let newdate = Moment(string, "DD/MM/YYYY");
  return newdate.isValid()
    ? newdate
    : Moment(new Date().toLocaleDateString("fr-FR"), "DD/MM/YYYY");
};

const formatTime = (stringtime) => {
  stringtime = stringtime.split(":");
  let now = new Date();
  return new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    ...stringtime
  );
};

const formatCheck = (val) => {
  return val === "Y" ? 1 : val === "OUI" ? 1 : 0;
};

const getDossierFormData = async (data) => {
  const fd = new FormData();
  for (let property in data) {
    if (property === "id_dossier" && data[property] === 0) {
      continue;
    }
    fd.append(property, data[property]);
  }
  return fd;
};

export const insertDossier_bkp = async (formValue) => {
  const fd = await getDossierFormData(formValue);
  try {
    let finalUrl = BASE_URL + dossierApi;
    const requestType = "post";
    // make axios post request
    return await axios[requestType](finalUrl, fd)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("ERROR post dossier: ", error);
        return error;
      });
  } catch (error) {
    console.log("unexpected ERROR post dossier: ", error);
    return error;
  }
};

export const insertDossier = async (formValue) => {
  const fd = await getDossierFormData(formValue);

  try {
    // make axios post request
    return await axios({
      method: "post",
      url: BASE_URL + dossierApi,
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("ERROR post dossier: ", error);
        return error;
      });
  } catch (error) {
    console.log("unexpected ERROR post dossier: ", error);
    return error;
  }
};

export function updateDossier(data) {
  console.log("updateDossier");
}

export function deleteDossier(id) {}

export const getNationalitesCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + nationaliteApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((nationalite) => ({
        ...nationalite,
        id: nationalite.id_nationalite,
        title: nationalite.nom_nationalite,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getOriginesGeographiquesCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + originegeographiqueApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((originegeographique) => ({
        ...originegeographique,
        id: originegeographique.id,
        title: originegeographique.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getTypesCarteIdentitesCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + typecarteidentiteApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((typecarteidentite) => ({
        ...typecarteidentite,
        id: typecarteidentite.id,
        title: typecarteidentite.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getSituationsProfessionnellesCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + situationProfessionnelleApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((situationprofessionnelle) => ({
        ...situationprofessionnelle,
        id: situationprofessionnelle.id,
        title: situationprofessionnelle.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getResourcesCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + ressourceApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((ressource) => ({
        ...ressource,
        id: ressource.id,
        title: ressource.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getNiveauxScolairesCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + niveauScolaireApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((niveauscolaire) => ({
        ...niveauscolaire,
        id: niveauscolaire.id,
        title: niveauscolaire.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getDroitsParentalCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + droitParentalApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((droitparental) => ({
        ...droitparental,
        id: droitparental.id,
        title: droitparental.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getStatutsHueCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + statutHueApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((statuthue) => ({
        ...statuthue,
        id: statuthue.id,
        title: statuthue.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getCompositionsFamillialesCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + compositionFamillialeApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((compoFamilliale) => ({
        ...compoFamilliale,
        id: compoFamilliale.id,
        title: compoFamilliale.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getHabitatsCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + habitatApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((habitat) => ({
        ...habitat,
        id: habitat.id,
        title: habitat.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getSituationsFamillialesCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + situationFamillialeApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((situationfamilliale) => ({
        ...situationfamilliale,
        id: situationfamilliale.id,
        title: situationfamilliale.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getCentresCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + centreApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((centre) => ({
        ...centre,
        id: centre.id,
        title: centre.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getInstructeursDemandeCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + instructeurDemandeApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((instructeurDemande) => ({
        ...instructeurDemande,
        id: instructeurDemande.id,
        title: instructeurDemande.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getOrientationsSiaoCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + orientationSiaoApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((orientationSiao) => ({
        ...orientationSiao,
        id: orientationSiao.id,
        title: orientationSiao.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getMotifsDemandeCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + motifDemandeApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((motifDemande) => ({
        ...motifDemande,
        id: motifDemande.id,
        title: motifDemande.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getPartenairesCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + partenaireApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((partenaire) => ({
        ...partenaire,
        id: partenaire.id,
        title: partenaire.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getTypesAccompagnementCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + typeAccompagnementApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((typeAccompagnement) => ({
        ...typeAccompagnement,
        id: typeAccompagnement.id,
        title: typeAccompagnement.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getMotifsSortieCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + motifSortieApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((motifSortie) => ({
        ...motifSortie,
        id: motifSortie.id,
        title: motifSortie.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getOrientationsSortieCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + orientationSortieApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((orientationSortie) => ({
        ...orientationSortie,
        id: orientationSortie.id,
        title: orientationSortie.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getCommissionCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + commissionApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((commission) => ({
        ...commission,
        id: commission.id,
        title: commission.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getLogementAutonomeCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + logementAutonomeApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((logementAutonome) => ({
        ...logementAutonome,
        id: logementAutonome.id,
        title: logementAutonome.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getComportementPersonneCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + comportementPersonneApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((comportementPersonne) => ({
        ...comportementPersonne,
        id: comportementPersonne.id,
        title: comportementPersonne.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getTypesHebergementCollection = async () => {
  const headerType = header;
  return await axios(BASE_URL + typeHebergementApi, {
    method: "GET",
    headers: headerType,
  })
    .then((response) => {
      return response.data.tab.map((typeHebergement) => ({
        ...typeHebergement,
        id: typeHebergement.id,
        title: typeHebergement.nom,
      }));
    })
    .catch((error) => {
      return {
        success: false,
        apiAnswer: true,
        error,
      };
    });
};

export const getStatutsDossierCollection = async () => {
  return await [
    { id: "dans la liste d attente", title: "Dans la liste d'attente" },
    { id: "traite", title: "Traité" },
  ];
};
