import React from 'react'
import PropTypes from 'prop-types'

const SelectField = (props) => {

    const {
        label,
        required,
        optionLists,
        onChangeHandler,
        name
    } = props


    return (
        <div className="col-span-6 sm:col-span-3" key={label}>
            <label htmlFor={label} className="block text-sm font-medium text-gray-700">{label}</label>
            <select onChange={onChangeHandler} required={required === 'ok' ? 'required' : ''} id={label} name={name} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option value="">Select</option>
                {
                    optionLists && Object.values(optionLists).map((option, index) => {
                        return <option value={option} key={`option-${index}`}>{option}</option>
                    })
                }
            </select>
        </div>
    )
}

SelectField.propTypes = {
    label: PropTypes.string,
    required: PropTypes.string,
    optionLists: PropTypes.object,
}

export default SelectField