import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import DossierForm from "./DossierForm";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import useTable from "../../components/useTable";
import * as dossierService from "../../helpers/helper";
import Controls from "../../components/controls/Controls";
import { Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { ArrowLeftOutlined } from "@material-ui/icons";
import Popup from "../../components/Popup";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { DeleteOutlined } from "@material-ui/icons";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";

import { headCells } from "./helper";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "60px",
  },
  tableRow: {
    height: 10,
  },
  tableCell: {
    padding: "0px 16px",
  },
  listTitle: {
    padding: "0px 0px 20px 0px",
  },
}));

export default function DossierDetails() {
  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [popupTitle, setPopupTitle] = useState("Créer Dossier");

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      DossierGet();
      setPopupTitle("Edition Dossier");
    } else {
      setRecordForEdit(null);
      setPopupTitle("Création Nouveau Dossier");
    }
  }, [id]);

  const DossierGet = () => {
    dossierService.getOneDossier(id).then((result) => {
      setRecordForEdit(result);
    });
  };

  const addOrEdit = (dossier, resetForm) => {
    if (dossier.id_dossier === 0) dossierService.insertDossier(dossier);
    else dossierService.updateDossier(dossier);
    //resetForm();
    //setRecordForEdit(null);
    //DossierGet();
    window.location.href = "#/menudossier/list";
    setNotify({
      isOpen: true,
      message: "Enregistré avec Succès",
      type: "success",
    });
  };

  return (
    <>
      <Paper
        className={classes.pageContent}
        style={{ maxHeight: 1000, minHeight: 1000, overflow: "auto" }}
      >
        <Typography variant="h6" className={classes.listTitle}>
          {popupTitle}
          <Link to="/menudossier/list">
            <Controls.Button
              text="Liste Dossiers"
              variant="outlined"
              startIcon={<ArrowLeftOutlined />}
              className={classes.newButton}
            />
          </Link>
        </Typography>
        <DossierForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
