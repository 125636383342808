import React from "react";
import PropTypes from "prop-types";

const RadioButton = (props) => {
  const { label, optionLists, required, onChangeHandler, name } = props;

  return (
    <div className="col-span-6 sm:col-span-3" key={label}>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-4 space-y-4">
        {optionLists &&
          Object.values(optionLists).map((option, index) => {
            return (
              <div className="flex items-center" key={`radio-${index}`}>
                <input
                  onChange={onChangeHandler}
                  required={required === "ok" ? "required" : ""}
                  id={option}
                  value={option}
                  name={name}
                  type="radio"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                />
                <label
                  htmlFor={option}
                  className="ml-3 block text-sm font-medium text-gray-700"
                >
                  {option}
                </label>
              </div>
            );
          })}
      </div>
    </div>
  );
};

RadioButton.propTypes = {
  label: PropTypes.string,
  optionLists: PropTypes.object,
  required: PropTypes.string,
};

export default RadioButton;
