import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

export default function StickyHeadTable(props) {
  const { tableData, deleteRecordHandler, updateRecordHandler } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const tableObj = tableData?.TableBody?.map(function (res) {
    return res;
  });

  return (
    <div className="mt-5 md:mt-0 md:col-span-2">
      <div className="shadow overflow-hidden sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div className="col-span-12">
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {tableData?.TableHeaders?.map((title, index) => {
                        return (
                          <TableCell
                            key={`${title}_${index}`}
                            style={{ minWidth: "170" }}
                          >
                            {title}
                          </TableCell>
                        );
                      })}
                      <TableCell style={{ minWidth: "170" }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableObj
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((res, index) => {
                        return (
                          <TableRow hover tabIndex={-1} key={`tbody_${index}`}>
                            {Object.values(res.data).map((cell, index) => (
                              <TableCell key={`tbody_cell_${index}`}>
                                {cell}
                              </TableCell>
                            ))}
                            <TableCell>
                              <Button
                                variant="contained"
                                size="small"
                                id={res.id}
                                onClick={() => updateRecordHandler(res.id)}
                              >
                                Update
                              </Button>{" "}
                              &nbsp;
                              <Button
                                variant="contained"
                                size="small"
                                color="error"
                                id={res.id}
                                onClick={() => deleteRecordHandler(res.id)}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableData?.TableBody.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}
