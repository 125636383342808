const headCellsAll = [
  {
    id: "id_dossier",
    label: "ID",
    initialValue: 0,
  },
  {
    id: "nom_personne",
    label: "Nom",
    initialValue: "",
  },
  {
    id: "prenom_personne",
    label: "Prénom",
    initialValue: "",
  },
  {
    id: "sexe_personne",
    label: "Sexe",
    initialValue: "H",
  },
  {
    id: "age_personne",
    label: "Age",
    initialValue: "",
  },
  {
    id: "id_nationalite",
    label: "ID Nationalité",
    initialValue: "",
  },
  {
    id: "nom_nationalite",
    label: "Nationalité",
    initialValue: "",
  },
  {
    id: "id_origine_demographique",
    label: "ID Origine démographique",
    initialValue: "",
  },
  {
    id: "nom_origine_demographique",
    label: "Origine démographique",
    initialValue: "",
  },
  {
    id: "id_type_carte_identite",
    label: "ID Type de carte d'identité",
    initialValue: "",
  },
  {
    id: "nom_type_carte_identite",
    label: "Type de carte d'identité",
    initialValue: "",
  },
  {
    id: "date_validite_type_carte_identite",
    label: "Date validité Type carte d'identité",
    initialValue: new Date(),
  },
  {
    id: "id_situation_professionnelle_a_arrivee",
    label: "ID Situation Professionnelle à l'arrivée",
    initialValue: "",
  },
  {
    id: "nom_situation_professionnelle_a_arrivee",
    label: "Situation professionnelle à l'arrivée",
    initialValue: "",
  },
  {
    id: "id_situation_professionnelle_a_sortie",
    label: "ID Situation Professionnelle à la sortie",
    initialValue: "",
  },
  {
    id: "nom_situation_professionnelle_a_sortie",
    label: "Situation professionnelle à la sortie",
    initialValue: "",
  },
  {
    id: "id_ressource_a_arrivee",
    label: "ID Ressource à l'arrivée",
    initialValue: "",
  },
  {
    id: "nom_ressource_a_arrivee",
    label: "Ressource à l'arrivée",
    initialValue: "",
  },
  {
    id: "montant_prestation",
    label: "Montant de la Prestation",
    initialValue: "",
  },
  {
    id: "id_ressource_a_sortie",
    label: "ID Ressource à la Sortie",
    initialValue: "",
  },
  {
    id: "nom_ressource_a_sortie",
    label: "Ressource à la Sortie",
    initialValue: "",
  },
  {
    id: "montant_prestation_sortie",
    label: "Montant de la Prestation Sortie",
    initialValue: "",
  },
  {
    id: "id_niveau_scolaire",
    label: "ID Niveau Scolaire",
    initialValue: "",
  },
  {
    id: "nom_niveau_scolaire",
    label: "Niveau Scolaire",
    initialValue: "",
  },
  {
    id: "id_droit_parental",
    label: "ID Droit Parental",
    initialValue: "",
  },
  {
    id: "nom_droit_parental",
    label: "Droit Parental",
    initialValue: "",
  },
  {
    id: "id_statut_hue",
    label: "ID Statut Hue",
    initialValue: "",
  },
  {
    id: "nom_statut_hue",
    label: "Statut Hue",
    initialValue: "",
  },
  {
    id: "id_composition_familliale",
    label: "ID Composition Familliale",
    initialValue: "",
  },
  {
    id: "nom_composition_familliale",
    label: "Composition Familliale",
    initialValue: "",
  },
  {
    id: "id_oirentation_siao",
    label: "ID Orientation SIAO",
    initialValue: "",
  },
  {
    id: "nom_oirentation_siao",
    label: "Orientation SIAO",
    initialValue: "",
  },
  {
    id: "text_orientation_siao_propose",
    label: "Orientation SIAO Proposée",
    initialValue: "",
  },
  {
    id: "id_orientation_sortie",
    label: "ID Orientation Sortie",
    initialValue: "",
  },
  {
    id: "nom_orientation_sortie",
    label: "Orientation Sortie",
    initialValue: "",
  },
  {
    id: "nom_nature_prise_en_charge",
    label: "Nature Prise En Charge",
    initialValue: "",
  },
  {
    id: "id_motif_sortie",
    label: "ID Motif Sortie",
    initialValue: "",
  },
  {
    id: "nom_motif_sortie",
    label: "Motif Sortie",
    initialValue: "",
  },
  {
    id: "id_habita_accueilli",
    label: "ID Habita Accueilli",
    initialValue: "",
  },
  {
    id: "nom_habita_accueilli",
    label: "Habitat des personnes Accueillies",
    initialValue: "",
  },
  {
    id: "id_centre",
    label: "ID Centre",
    initialValue: "",
  },
  {
    id: "nom_centre",
    label: "Centre",
    initialValue: "",
  },
  {
    id: "nom_motif_refus_struture",
    label: "Motif Refus Struture",
    initialValue: "",
  },
  {
    id: "nom_motif_refus_demandeur",
    label: "Motif Refus Demandeur",
    initialValue: "",
  },
  {
    id: "id_instructeur_demande",
    label: "ID Instructeur Demande",
    initialValue: "",
  },
  {
    id: "nom_instructeur_demande",
    label: "Instructeur Demande",
    initialValue: "",
  },
  {
    id: "id_motif_demande",
    label: "ID Motif Demande",
    initialValue: "",
  },
  {
    id: "nom_motif_demande",
    label: "Motif Demande",
    initialValue: "",
  },
  {
    id: "id_situation_familliale",
    label: "ID Situation Familliale",
    initialValue: "",
  },
  {
    id: "nom_situation_familliale",
    label: "Situation Familliale",
    initialValue: "",
  },
  {
    id: "id_logement_automone",
    label: "ID Logement Automone",
    initialValue: "",
  },
  {
    id: "nom_logement_automone",
    label: "Logement Automone",
    initialValue: "",
  },
  {
    id: "id_partenaire_sollicite",
    label: "ID Partenaire Sollicite",
    initialValue: "",
  },
  {
    id: "nom_partenaire_sollicite",
    label: "Partenaire Sollicite",
    initialValue: "",
  },
  {
    id: "id_affection_commission",
    label: "ID Affection à une commission",
    initialValue: "",
  },
  {
    id: "nom_affection_commission",
    label: "Affection à une commission",
    initialValue: "",
  },
  {
    id: "nom_logement_social",
    label: "Logement Social",
    initialValue: "",
  },
  {
    id: "personne_oriente_vers_siao_dossier",
    label: "Personne Orientée Vers SIAO Dossier",
    initialValue: "NON",
  },
  {
    id: "personne_oriente_par_siao_dossier",
    label: "Personne Orientée Par SIAO Dossier",
    initialValue: "NON",
  },
  {
    id: "nombre_enfant_dossier",
    label: "Nombre Enfant Dossier",
    initialValue: "",
  },
  {
    id: "nombre_aminaux_dossier",
    label: "Nombre Animaux Dossier",
    initialValue: "",
  },
  {
    id: "champplus_instructeur_dossier",
    label: "Champ Plus Instructeur Dossier",
    initialValue: "",
  },
  {
    id: "champplus_habitataccueilli_dossier",
    label: "Champ Plus habitat Accueilli Dossier",
    initialValue: "",
  },
  {
    id: "precision_type_accompagnement",
    label: "Précision Type Accompagnement demandé",
    initialValue: "",
  },
  {
    id: "id_type_accompagnement_demande",
    label: "ID Type d'accompagnement demandé",
    initialValue: "",
  },
  {
    id: "nom_type_accompagnement_demande",
    label: "Type d'accompagnement demandé",
    initialValue: "",
  },
  {
    id: "id_type_accompagnement_realise",
    label: "ID Type d'accompagnement réalisé",
    initialValue: "",
  },
  {
    id: "nom_type_accompagnement_realise",
    label: "Type d'accompagnement réalisé",
    initialValue: "",
  },
  {
    id: "precision_type_accompagnement_realise",
    label: "Précision Type Accompagnement Réalisé",
    initialValue: "",
  },
  {
    id: "champplus_motif_sortie_dossier",
    label: "Champ Plus Motif Sortie",
    initialValue: "",
  },
  {
    id: "observation_dossier",
    label: "Observation sur le dossier",
    initialValue: "",
  },
  {
    id: "dateenreg",
    label: "Date Enregistrement",
    initialValue: new Date(),
  },
  {
    id: "datecloture",
    label: "Date Clotûre",
    initialValue: new Date(),
  },
  {
    id: "radiopounon12mois_dossier",
    label: "Radio des poumons au cours des 12 derniers Mois",
    initialValue: "NON",
  },
  {
    id: "radiopropose_dossier",
    label: "Radio Proposée",
    initialValue: "NON",
  },
  {
    id: "listeattente_dossier",
    label: "Liste Attente Dossier",
    initialValue: "",
  },
  {
    id: "radioeffectue_dossier",
    label: "Radio Effectuée",
    initialValue: "NON",
  },
  {
    id: "datearrive",
    label: "Date Arrivée",
    initialValue: new Date(),
  },
  {
    id: "heurearrive",
    label: "Heure Arrivée",
    initialValue: new Date(),
  },
  {
    id: "heuredepart",
    label: "Heure Départ",
    initialValue: new Date(),
  },
  {
    id: "dateturneover",
    label: "Date Turne Over",
    initialValue: new Date(),
  },
  {
    id: "vue_par_agent_dossier",
    label: "Vue Par Agent Dossier",
    initialValue: "NON",
  },
  {
    id: "probeme_sante_dossier",
    label: "Problème Santé",
    initialValue: "NON",
  },
  {
    id: "dateentrecava",
    label: "Date Entrée CAVA",
    initialValue: new Date(),
  },
  {
    id: "datesortiecava",
    label: "Date Sortie CAVA",
    initialValue: new Date(),
  },
  {
    id: "nom_orientation_cava",
    label: "Orientation CAVA",
    initialValue: "",
  },
  {
    id: "decision_structure",
    label: "Décision de la structure",
    initialValue: "",
  },
  {
    id: "decision_demandeur",
    label: "Décision du demandeur",
    initialValue: "",
  },
  {
    id: "depistage_hepatite_4dernier_mois",
    label: "Dépistage de l'hépatite au cours des 4 dernier mois",
    initialValue: "",
  },
  {
    id: "ehm_propose",
    label: "EHM proposé",
    initialValue: "",
  },
  {
    id: "ehm_realise",
    label: "EHM réalisé",
    initialValue: "",
  },
  {
    id: "personne_enceinte",
    label: "Personne enceinte",
    initialValue: "",
  },
  {
    id: "vue_par_travailleur_social",
    label: "Vue par le travailleur social",
    initialValue: "NON",
  },
  {
    id: "comportement_personne",
    label: "Comportement de la personne",
    initialValue: "",
  },
  {
    id: "type_hebergement",
    label: "Type hébergement",
    initialValue: "",
  },
  {
    id: "statut_dossier",
    label: "Statut Dossier",
    initialValue: "dans la liste d attente",
  },
  {
    id: "fin_prise_en_charge",
    label: "Fin de Prise en Charge",
    initialValue: "NON",
  },
  {
    id: "post_commission",
    label: "Post commission",
    initialValue: "",
  },
  {
    id: "decision_commission",
    label: "Décision commission",
    initialValue: "",
  },
];

const getHeadLabel = (id) => {
  let rest = headCellsAll.find((obj) => obj.id === id);
  return rest.label;
};

const getFieldsInitValues = () => {
  let initValues = {};
  let i = 0;
  for (i = 0; i < headCellsAll.length; i++) {
    var id = headCellsAll[i].id;
    var initV = headCellsAll[i].initialValue;
    initValues[id] = initV;
  }
  return initValues;
};

const headCells = [
  {
    id: "id_dossier",
    label: "ID",
  },
  {
    id: "nom_personne",
    label: "Nom",
  },
  {
    id: "prenom_personne",
    label: "Prénom",
  },
  {
    id: "sexe_personne",
    label: "Sexe",
  },
  {
    id: "nom_nationalite",
    label: "Nationalité",
  },
  {
    id: "nom_composition_familliale",
    label: "Compo Familliale",
  },
  { id: "actions", label: "Actions", disableSorting: true },
];

export { headCells, headCellsAll, getHeadLabel, getFieldsInitValues };
