import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InputField, SelectField, RadioButton, Checkbox } from '../inputFields/index'
import Button from '@mui/material/Button';

const DynamicForm = (props) => {

    const { submitHandler, formData, onChangeHandler, submitButtonText } = props
    const [ramdomId, setRamdomID] = useState(String(Math.random()).slice(-4))

    const renderForm = (element) => {

        const {
            type_champ: type,
            text_label: label,
            ctrl_champ: required,
            lesitem: optionLists,
            mapping_champ: name
        } = element


        switch (type) {
            case 'text':
            case 'date':
            case 'time':
                return <InputField
                    label={label}
                    type={type}
                    required={required}
                    onChangeHandler={onChangeHandler}
                    name={name}
                    key={`label_${label}`}
                />
            case 'select':
                return <SelectField
                    label={type}
                    required={required}
                    optionLists={optionLists}
                    onChangeHandler={onChangeHandler}
                    name={name}
                    key={`label_${label}`}
                />
            case 'radio':
                return <RadioButton
                    label={label}
                    required={required}
                    optionLists={optionLists}
                    onChangeHandler={onChangeHandler}
                    name={name}
                    key={`label_${label}`}
                />
            case 'checkbox':
                return <Checkbox
                    label={label}
                    required={required}
                    optionLists={optionLists}
                    onChangeHandler={onChangeHandler}
                    name={name}
                    key={`label_${label}`}
                />

            default:
                return <div>Invalid Field Type</div>
        }
    }

    return (
        <form className="col-span-12" onSubmit={submitHandler} id={`formID_${ramdomId}`}>
            <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                            {
                                formData?.map((element) => {
                                    return renderForm(element)
                                })
                            }
                        </div>
                        <div className="col-span-12 mt-5">
                            <Button variant="contained" type="submit">{submitButtonText}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

DynamicForm.propTypes = {
    submitHandler: PropTypes.func,
    formData: PropTypes.array,
    onChangeHandler: PropTypes.func
}

export default DynamicForm