export const SET_LOADER = "SET_LOADER";
export const SET_FAILED_MSG = "SET_FAILED_MSG"
export const SET_SUCCESS_MSG = "SET_SUCCESS_MSG"
export const SET_SIDENAV = "SET_SIDENAV"
export const SET_ROUTES = "SET_ROUTES"

export const setLoader = (data) => {
    return {
        type: SET_LOADER,
        data
    }
}

export const setRoutes = (routes) => {
    return {
        type: SET_ROUTES,
        routes
    }
}

export const setSideNav = (data) => {
    return {
        type: SET_SIDENAV,
        data
    }
}